<!-- 密码框 -->
<template>
  <el-input
    v-model="inputValue"
    :placeholder="placeholder"
    type="password"
    :maxlength="maxlength"
    :minlength="minlength"
    :readonly="readonly"
    :disabled="disabled"
    :clearable="clearable"
  ></el-input>
</template>

<script>
export default {
  name: "PassInput",
  data() {
    return {};
  },

  props: {
    value: String,
    placeholder: String,
    maxlength: Object | Number,
    minlength: Object | Number,
    readonly: Boolean,
    disabled: Boolean,
    clearable: Boolean,
  },
  components: {},

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped></style>
