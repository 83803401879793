<!--  -->
<template>
  <div class="applyCar" v-if="detailInfo">
    <p class="title">{{ detailInfo.name }}</p>
    <van-form class="main-wrapper" ref="createForm">
      <div class="select-box">
        <van-field
          required
          readonly
          clickable
          name="cartCode"
          v-model="platingNumber"
          label="选择车辆"
          placeholder="请选择车辆"
          @click="showCarList = true"
          right-icon="arrow"
        >
        </van-field>
        <van-popup v-model="showCarList" position="bottom">
          <van-picker
            v-if="carList && carList.length"
            show-toolbar
            :columns="carList"
            value-key="platingNumber"
            @confirm="carListConfirm"
            @cancel="showCarList = false"
          />
          <van-empty v-else description="暂无车辆" />
        </van-popup>
        <van-field
          required
          readonly
          clickable
          name="applyDate"
          v-model="form.applyDate"
          label="用车日期"
          placeholder="请选择用车日期"
          @click="showTimePicker = true"
          right-icon="arrow"
        />
        <van-popup v-model="showTimePicker" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="applyDateConfirm"
            @cancel="showCarList = false"
          />
        </van-popup>
        <van-field
          required
          readonly
          clickable
          name="beginTime"
          v-model="beginTime"
          label="开始时间"
          placeholder="请选择开始时间"
          maxlength="50"
          right-icon="arrow"
          @click="showBeginTimeDialog"
        />
        <van-popup v-model="showBeginTime" position="bottom">
          <van-picker
            show-toolbar
            :columns="timeList"
            title="选择时间"
            @confirm="beginTimeConfirm"
            @cancel="showBeginTime = false"
          >
            <template #option="option">
              <div>
                <span>{{ option.beginTime }}</span>
                <span v-if="0 == option.status">已超时</span>
                <span v-if="2 == option.status">已预定</span>
                <span v-if="3 == option.status">我的预定</span>
              </div>
            </template>
          </van-picker>
        </van-popup>

        <van-field
          required
          readonly
          clickable
          v-model="endTime"
          name="endTime"
          label="预计结束时间"
          placeholder="请选择预计结束时间"
          maxlength="50"
          @click="showEndTimeDialog"
          right-icon="arrow"
        />
        <van-popup v-model="showEndTime" position="bottom">
          <van-picker
            show-toolbar
            :columns="timeList"
            title="选择时间"
            value-key="time"
            @confirm="endTimeConfirm"
            @cancel="showEndTime = false"
          >
            <template #option="option">
              <div>
                <span>{{ option.time }}</span>
                <span v-if="0 == option.status">（已超时）</span>
                <span v-if="2 == option.status">（已预定）</span>
                <span v-if="3 == option.status">（我的预定）</span>
              </div>
            </template>
          </van-picker>
        </van-popup>
        <!-- 去向 -->
        <van-field
          v-model="form.origin"
          name="origin"
          label="去向"
          placeholder="请输入去向"
          maxlength="50"
          required
        />
        <van-field
          required
          readonly
          clickable
          name="cardType"
          v-model="cardType"
          label="借用油卡"
          placeholder="请选择是否借用油卡"
          @click="showCardType = true"
          right-icon="arrow"
        >
        </van-field>
        <van-popup v-model="showCardType" position="bottom">
          <van-picker
            show-toolbar
            :columns="carTypeList"
            @confirm="carTypeConfirm"
            @cancel="showCardType = false"
          />
        </van-popup>
        <van-field
          v-if="1 == form.cardType"
          required
          readonly
          clickable
          name="cardNo"
          v-model="cardName"
          label="选择油卡"
          placeholder="请选择油卡"
          @click="showCardNo = true"
          right-icon="arrow"
        >
        </van-field>
        <van-popup v-model="showCardNo" position="bottom">
          <van-picker
            show-toolbar
            :columns="oilList"
            value-key="cardName"
            @confirm="carNoConfirm"
            @cancel="showCardNo = false"
          />
        </van-popup>
      </div>
      <div class="card">
        <div class="sub-title"><i style="color: red">*</i> 用车事由</div>
        <textarea
          v-model="form.reason"
          cols="30"
          rows="10"
          class="text-area"
          maxlength="120"
        ></textarea>
      </div>
      <div class="card">
        <div class="sub-title">内部乘车人</div>
        <div class="tag-ul">
          <van-tag
            v-for="(item, index) in passengerList"
            :key="index"
            closeable
            size="medium"
            type="primary"
            @close="delPassenger(item, index)"
          >
            {{ item.userName }}
          </van-tag>
        </div>
        <img
          :src="addImg"
          alt=""
          class="add-img"
          @click="addPassenger"
          v-if="passengerList.length < 4"
        />
      </div>
      <van-popup v-model="showPassenger" position="bottom" round>
        <staffCascader @staffChange="changePassenger" />
      </van-popup>
    </van-form>
  </div>
</template>

<script>
import addImg from "@/assets/images/applyCar/add.jpg";
import { Toast } from "vant";
import { queryCardList, queryCarList } from "@/api/common/index.js";
import { getAvailableList } from "@/api/cart/manage/index.js";
import staffCascader from "./components/staffCascader.vue";
export default {
  data() {
    return {
      addImg,
      showCarList: false,
      showTimePicker: false,
      showBeginTime: false,
      showEndTime: false,
      showCardType: false,
      showPassenger: false,
      showCardNo: false,
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(2040, 11, 31),
      currentDate: new Date(),
      carList: [],
      form: {
        applyDate: "",
        beginTime: "",
        cartCode: "",
        origin: "",
        endTime: "",
        reason: "",
        cardType: "",
        cardNo: "",
        passengers: [],
      },
      platingNumber: "",
      timeList: [],
      beginTime: "",
      endTime: "",
      cardName: "",
      cardType: "",
      carTypeList: [
        {
          value: 1,
          text: "是",
        },
        {
          value: 0,
          text: "否",
        },
      ],
      oilList: [],
      passengerList: [],
    };
  },
  filters: {
    formatePassengerList(val) {
      let arr = val.map((item) => {
        return item.userName;
      });
      return arr.join(",");
    },
  },
  props: {
    detailInfo: Object,
    formContent: String,
  },
  watch: {
    "form.cartCode"() {
      if (!this.form.applyDate) {
        return;
      }
      getAvailableList({
        date: this.form.applyDate,
        platingNumber: this.platingNumber,
      }).then((res) => {
        this.timeList = res.data[0].timeDetail.map((item) => {
          return {
            ...item,
            disabled: 1 != item.status,
          };
        });
      });
    },
    "form.applyDate"() {
      if (!this.form.applyDate) {
        return;
      }
      getAvailableList({
        date: this.form.applyDate,
        platingNumber: this.platingNumber,
      }).then((res) => {
        this.timeList = res.data[0].timeDetail.map((item) => {
          return {
            ...item,
            disabled: 1 != item.status,
          };
        });
      });
    },
    formContent() {
      this.initData();
    },
  },
  components: { staffCascader },

  computed: {
    tagKey() {
      if (!this.$route.query || !this.$route.query.tagKey) return "";
      return this.$route.query.tagKey || "";
    },
  },
  async mounted() {
    await this.formatDate();
    await this.getCarList();
    await this.getOilList();
  },

  methods: {
    initData() {
      if (this.formContent) {
        this.form = JSON.parse(this.formContent);
        if (this.form) {
          let car = this.carList.find((item) => {
            return item.bizCode == this.form.cartCode;
          });
          if (car) {
            this.platingNumber = car.platingNumber;
          }
          let carT = this.carTypeList.find((item) => {
            return item.value == this.form.cardType;
          });
          if (carT) {
            this.cardType = carT.text;
          }

          if (this.form.cardType == 1) {
            let card = this.oilList.find((item) => {
              return item.cardNo == this.form.cardNo;
            });
            if (card) {
              this.cardName = card.cardName;
            }
          }
          if (this.form.passengers.length > 0) {
            this.passengerList = this.form.passengers;
          }
          let oliObj = this.oilList.find((e) => {
            return e.cardNo == this.form.cardNo;
          });

          if (oliObj) {
            this.cardName = oliObj.cardName;
          }

          this.beginTime = this.form.beginTime;
          this.endTime = this.form.endTime;
        }
      }
    },
    formatDate() {
      const date = new Date();
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      this.date = `${year}-${month}-${day}`;
    },
    getCarList() {
      queryCarList({
        pageNum: 1,
        pageSize: 99,
      }).then((res) => {
        this.carList = res.data.records;
        this.initData();
      });
    },
    getOilList() {
      queryCardList({
        pageNum: 1,
        pageSize: 99,
      }).then((res) => {
        this.oilList = res.data.records;
      });
    },
    carListConfirm(e) {
      this.showCarList = false;
      this.platingNumber = e.platingNumber;
      this.form.cartCode = e.bizCode;
    },
    applyDateConfirm(e) {
      this.showTimePicker = false;
      let year = e.getFullYear();
      let month = e.getMonth() + 1;
      let day = e.getDate();

      this.form.applyDate = `${year}-${String(month).padStart(2, "0")}-${String(
        day
      ).padStart(2, "0")}`;
    },
    beginTimeConfirm(item, index) {
      this.beginTime = item.beginTime;
      this.form.beginTime = item.beginTime;
      this.showBeginTime = false;
    },
    endTimeConfirm(item, index) {
      this.endTime = item.time;
      this.form.endTime = item.time;
      this.showEndTime = false;
    },

    carTypeConfirm(item, index) {
      this.form.cardType = item.value;
      this.cardType = item.text;
      this.showCardType = false;
    },
    showBeginTimeDialog() {
      if (!this.form.cartCode || !this.form.applyDate) {
        Toast("请先选择车辆和用车日期");
        return;
      }
      this.showBeginTime = true;
    },
    showEndTimeDialog() {
      if (!this.form.cartCode || !this.form.applyDate) {
        Toast("请先选择车辆和用车日期");
        return;
      }
      this.showEndTime = true;
    },
    carNoConfirm(e) {
      this.showCardNo = false;
      this.cardName = e.cardName;
      this.form.cardNo = e.cardNo;
    },
    addPassenger() {
      this.showPassenger = true;
    },
    changePassenger(item) {
      let indexItem = this.passengerList.findIndex((listItem) => {
        return item.departmentCode == listItem.staffCode;
      });
      if (indexItem > -1) {
        Toast("不可以重复添加");
        return;
      }
      this.showPassenger = false;
      this.passengerList.push({
        staffCode: item.departmentCode,
        userName: item.departmentName,
      });
    },
    delPassenger(item, index) {
      this.passengerList.splice(index, 1);
    },

    //动态form校验
    vaildateDyForm() {
      return new Promise((resolve, reject) => {
        this.$refs.createForm
          .validate([
            "applyDate",
            "beginTime",
            "cartCode",
            "origin",
            "endTime",
            "cardType",
          ])
          .then(() => {
            resolve(true);
          });
      });
    },
    async validate() {
      const val = await this.vaildateDyForm();

      this.form.passengers = this.passengerList;
      if (val) {
        return {
          formValue: JSON.stringify(this.form),
        };
      } else {
        this.$message.error("请检查流程单");
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.applyCar {
  background: #fff;
  padding: 0.2rem 0.3rem;
  .title {
    height: 0.42rem;
    font-size: 0.3rem;
    font-weight: bold;
    color: #333333;
    line-height: 0.42rem;
    margin-bottom: 0.3rem;
  }
  .card {
    background: #fff;
    padding: 0.2rem;
    margin-top: 0.2rem;
    .sub-title {
      color: #646566;
      font-size: 14px;
      margin-bottom: 0.2rem;
    }
    .star::before {
      position: absolute;
      left: 8px;
      color: #ee0a24;
      font-size: 14px;
      content: "*";
    }

    .tag-ul {
      margin-top: 0.2rem;
      margin-bottom: 0.3rem;
      .van-tag {
        margin-right: 0.3rem;
      }
    }

    .text-area {
      background: #f6fafe;
      border-radius: 0.1rem;
      width: 100%;
      padding: 0.2rem 0.3rem;
    }

    .add-img {
      height: 1rem;
      width: 1rem;
    }
  }
}
</style>
