<!--  -->
<template>
  <div class="form-auto" v-if="detailInfo">
    <p class="title">{{ detailInfo.name }}</p>
    <form-create
      v-if="rule && rule.length"
      v-model="formCreate.fApi"
      :rule="rule"
      :option="formCreate.option"
      :value.sync="dyForm"
      name="form"
      ref="createForm"
    ></form-create>
  </div>
  <div v-else style="background: #fff">暂无数据</div>
</template>

<script>
import formCreate from "@/components/FormCreate";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      formCreate: {
        option: {
          form: {
            labelPosition: "top",
            size: "mini",
            hideRequiredAsterisk: false,
            showMessage: true,
            inlineMessage: false,
          },
          submitBtn: false,
          resetBtn: false,
        },
        fApi: {},
      },
      dyForm: {},
      autoForm: null, //获取自定义表单
    };
  },
  props: {
    detailInfo: Object,
    formContent: String,
  },
  watch: {
    formContent() {
      this.dyForm = JSON.parse(this.formContent);
    },
  },
  components: { FormCreate: formCreate.$form() },

  computed: {
    rule() {
      let arr = this.detailInfo.processFormInfo
        ? JSON.parse(this.detailInfo.processFormInfo)
        : [];
      arr.map((item) => {
        if (item.effect) {
          if (item.effect.fetch) {
            if (item.effect.fetch.headers) {
              item.effect.fetch.headers = {
                Authorization: `${localStorage.getItem("Authorization_OA_H5")}`,
                "User-Source": "APP_OA",
              };
            }
          }
        }
      });
      // console.log(arr);

      return arr;
    },
  },
  mounted() {
    if (this.formContent) {
      this.dyForm = JSON.parse(this.formContent);
    }
  },
  methods: {
    //动态form校验
    vaildateDyForm() {
      return new Promise((resolve, reject) => {
        if (this.rule && this.rule.length) {
          this.formCreate.fApi.validate((valid, fail) => {
            resolve(valid);
          });
        } else {
          resolve(true);
        }
      });
    },

    async validate() {
      const val = await this.vaildateDyForm();

      if (val) {
        return {
          formValue:
            this.rule && this.rule.length
              ? JSON.stringify(this.dyForm)
              : undefined,
        };
      } else {
        this.$message.error("请检查流程单");
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-auto {
  width: 100%;
  background: #fff;
  padding: 0.2rem 0.3rem;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
  .title {
    height: 0.42rem;
    font-size: 0.3rem;
    font-weight: bold;
    color: #333333;
    line-height: 0.42rem;
    margin-bottom: 0.3rem;
  }
  .form-create {
    ::v-deep .el-checkbox {
      margin-right: 0.3rem;
      .el-checkbox__label {
        white-space: break-spaces;
        vertical-align: text-top;
        word-wrap: break-word;
      }
    }
    ::v-deep .el-radio {
      margin-right: 0.3rem;
      margin-bottom: 0.2rem;
      .el-radio__label {
        white-space: break-spaces;
        vertical-align: text-top;
        word-wrap: break-word;
        display: inline-block;
        line-height: 19px;
      }
    }
  }
}
</style>
