<!--  -->
<template>
  <ul class="list" v-if="list && list.length">
    <li v-for="(item, index) in list" :key="index" @click="goDetail(item)">
      <approval-item :info="item" />
    </li>
  </ul>
  <van-empty v-else description="暂无数据" />
</template>

<script>
import { taskPage } from "@/api/processApplyInfo";
import ApprovalItem from "./ApprovalItem";

export default {
  data() {
    return {
      listQuery: {
        applyEndTime: "",
        applyStartTime: "",
        applyUser: "",
        pageNum: 1,
        pageSize: 99,
        processCode: "",
        searchType: "1",
        /* searchType	查询类型 1-已发布；2-我收到的；3-待处理；4-已处理 */
        descs: "urgent,apply_time",
      },
      total: 0,
      list: [],
    };
  },

  components: { ApprovalItem },

  computed: {},
  filters: {
    formatUrgent(val) {
      const rules = {
        1: "否",
        2: "是",
      };
      return rules[val] || "";
    },
    formatHisActivity(info) {
      let length = info.nodeInfo.hisActivityList.length;
      let obj = info.nodeInfo.hisActivityList[length - 1];
      let rules = {
        1: "审批中",
        2: "审批通过",
        3: "审批驳回",
        4: "已撤销",
      };
      if (1 == info.status) {
        return `${obj.assigneeName}${rules[info.status] || ""}`;
      } else {
        return rules[info.status] || "";
      }
    },
  },
  mounted() {
    this.getTaskList();
  },

  methods: {
    getTaskList() {
      const params = {
        ...this.listQuery,
        processCode: this.$route.query.bizCode,
      };
      taskPage(params).then((res) => {
        let { data } = res;
        if (data) {
          this.list = data.records;
          this.total = data.total;
        }
      });
    },
    handleCurrentChange(e) {
      this.listQuery.pageNumber = e;
      this.getTaskList();
    },

    goDetail(item) {
      let path = "/approvalDetail";
      if (item.tagKey == "CAR_APPLY_APPROVE") {
        path = "/approvalCarDetail";
      }
      this.$router.push({
        path: path,
        query: {
          bizCode: item.bizCode,
          processCode: item.processCode,
          searchType: 1,
          applyNo: item.applyNo,
          tagKey: item.tagKey,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  background: #fafafa;
  min-height: 100%;
  padding: 0.3rem 0;
}
</style>
