<!--  -->
<template>
  <el-time-picker
    is-range
    arrow-control
    v-model="inputValue"
    range-separator="至"
    start-placeholder="开始时间"
    end-placeholder="结束时间"
    placeholder="选择时间范围"
    value-format="HH:mm:ss"
  >
  </el-time-picker>
</template>

<script>
export default {
  name: "timeRange",
  data() {
    return {};
  },
  props: {
    value: [Array, Object],
  },
  components: {},

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped></style>
