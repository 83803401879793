var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"approval-li"},[_c('div',{staticClass:"row1"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.info.applyTitle))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(_vm.info.applyTime))])]),_vm._l((_vm.designValue),function(item,index){return _c('div',{key:index,staticClass:"msg"},[_c('span',[_vm._v(_vm._s(item.title)+":"+_vm._s(item.value))])])}),_c('div',{staticClass:"row5"},[_c('div',{staticClass:"submit-from"},[_vm._v(" 由 "+_vm._s(_vm.info.formInfo.formValue.userName)+" 提交 ")]),(2 == _vm.info.urgent)?_c('div',{staticClass:"hurry"},[_vm._v(" "+_vm._s(_vm._f("formatUrgent")(_vm.info.urgent))+" ")]):_vm._e(),_c('div',{class:{
        doing: true,
        approving: 1 == _vm.info.status,
        accept: 2 == _vm.info.status,
        reject: 3 == _vm.info.status,
        cancel: 4 == _vm.info.status,
      }},[_vm._v(" "+_vm._s(_vm._f("formatHisActivity")(_vm.info))+" ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }