<!--  -->
<template>
  <div class="process-list">
    <p class="title">审批流程</p>

    <ul class="process-ul" v-if="detailInfo">
      <li v-for="(item, index) in hisActivityList" :key="index" class="item">
        <div class="left">
          <img :src="approvalAvatar" alt="" class="avatar" />
          <div class="split"></div>
        </div>
        <div class="right">
          <div class="row1">
            <div class="name">{{ item.activityName }}</div>
          </div>
          <div class="status">{{ item.assigneeName }}</div>

          <van-form
            v-if="!item.assigneeName && 1 == index"
            class="form"
            :model="form"
            label-width="120px"
            ref="form"
            :rules="rules"
          >
            <van-field
              v-model="form.handlerUsrName"
              readonly
              label=""
              placeholder="请选择审批人"
              @click="usrshow = true"
            />
            <van-popup v-model="usrshow" round position="bottom">
              <van-cascader
                v-model="cascaderValue"
                title="请选择审批人"
                :options="options"
                @close="usrshow = false"
                @change="onChange"
                @finish="onFinish"
                :field-names="fieldNames"
              />
            </van-popup>
          </van-form>
        </div>
      </li>

      <li class="item" v-if="userList">
        <div class="left">
          <img :src="approvalAvatar" alt="" class="avatar" />
          <div class="split"></div>
        </div>
        <div class="right">
          <div class="row1">
            <div class="name">抄送人</div>
          </div>
          <div class="status">{{ userList.toString() }}</div>
        </div>
      </li>
    </ul>
    <div v-else style="background: #fff">暂无数据</div>
  </div>
</template>

<script>
import { getList } from "@/api/department/index.js";
import cloneDeep from "lodash/cloneDeep";
import { Toast } from "vant";

import {
  queryProcessConfigInfo,
  schedule,
} from "@/api/processApplyInfo/index.js";
import approvalAvatar from "@/assets/images/approvalDetail/approvalAvatar.png";

const defaultData = {
  handlerUsrCode: "",
  handleUsrName: "",
};
export default {
  props: {
    formResults: Object,
  },
  data() {
    return {
      usrshow: false,
      detailInfo: null, //审批单详情
      dataInfo: null, //流程详情
      hisActivityList: null,
      userList: null,
      form: cloneDeep(defaultData),
      props: {
        label: "departmentName",
        children: "children",
        isLeaf: "leaf",
        disabled(data, node) {
          if (data.type == 1) return false;
          return true;
        },
      },
      options: [],
      rules: {
        handlerUsrCodeList: [{ required: true, message: "请选择审批人" }],
      },
      approvalAvatar,

      cascaderValue: null,
      fieldNames: {
        text: "departmentName",
        value: "departmentCode",
        children: "children",
      },
    };
  },

  components: {},

  computed: {},
  watch: {
    formResults() {},
  },

  mounted() {
    if (this.$route.query.bizCode) {
      this.getProcessConfigInfo();
    }

    this.loadNode();
  },
  filters: {},

  methods: {
    loadNode() {
      getList().then((res) => {
        let { data, success, msg } = res;
        this.options = res.data.map((item) => {
          if (0 == item.type) {
            return {
              ...item,
              children: [],
            };
          } else {
            return item;
          }
        });
      });
    },
    onChange(item) {
      let length = item.selectedOptions.length;
      if (1 == item.selectedOptions[length - 1].type) {
        return;
      }
      getList({ departmentCode: item.value }).then((res) => {
        item.selectedOptions[length - 1].children = res.data.map((item) => {
          if (0 == item.type) {
            return {
              ...item,
              children: [],
            };
          } else {
            return item;
          }
        });
      });
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish(item) {
      this.usrshow = false;
      let length = item.selectedOptions.length;
      this.form.handlerUsrCode =
        item.selectedOptions[length - 1].departmentCode;
      this.form.handlerUsrName =
        item.selectedOptions[length - 1].departmentName;
    },

    getCheckedNodes() {
      const list = this.$refs.cascader.getCheckedNodes();
      this.changeNames(list);
      this.$emit("change", list);
    },

    validForm() {
      return new Promise((resolve, reject) => {
        if (this.hisActivityList[1].assigneeName) {
          resolve("2000");
          return;
        }
        if (this.form.handlerUsrCode) {
          resolve(this.form.handlerUsrCode);
          return;
        }
        Toast("请输入审批人");
        reject();
      });
    },

    /* 获取配置的审批单详情 */
    getProcessConfigInfo() {
      queryProcessConfigInfo({ processCode: this.$route.query.bizCode }).then(
        (res) => {
          let { data, success } = res;
          this.detailInfo = data;
          this.getProcess();
        }
      );
    },
    /* 获取流程进度
    applyCode	applyCode
    processCode
    */
    getProcess() {
      const params = {
        processCode: this.detailInfo.processCode,
        applyCode: "",
        formValue: this.formResults,
      };
      schedule(params).then((res) => {
        let { data } = res;
        this.dataInfo = data;
        // console.log(data);
        this.hisActivityList = data.hisActivityList;
        this.userList = data.noticeUsers;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.process-list {
  width: 100%;
  min-height: 50%;
  background: #fff;
  padding: 0.2rem 0.3rem;
  .title {
    height: 0.42rem;
    font-size: 0.3rem;
    font-weight: bold;
    color: #333333;
    line-height: 0.42rem;
    margin-bottom: 0.3rem;
  }

  .process-ul {
    .item {
      display: flex;
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 0.9rem;
        margin-right: 0.2rem;
        .avatar {
          width: 0.9rem;
          height: 0.9rem;
          border-radius: 0.45rem;
        }
        .split {
          width: 0.02rem;
          background: #cccccc;
          flex: 1;
        }
      }

      .right {
        flex: 1;
        margin-bottom: 0.6rem;
        .row1 {
          display: flex;
          justify-content: space-between;
          .name {
            height: 0.42rem;
            font-size: 0.3rem;
            color: #333333;
            line-height: 0.42rem;
          }
          .time {
            height: 0.37rem;
            font-size: 0.26rem;
            color: #999999;
            line-height: 0.37rem;
          }
        }
        .status {
          height: 0.37rem;
          font-size: 0.26rem;
          color: #999999;
          line-height: 0.37rem;
        }
        .form {
          .usr-code {
            background: #fafafa;
            height: 0.6rem;
            width: 100%;
            padding: 0 0.2rem;
          }
        }
      }
    }
    .item:last-of-type {
      .left {
        .split {
          display: none;
        }
      }
      .right {
        margin-bottom: 0.6rem;
      }
    }
  }
}
</style>
