<template>
   
  <el-link
    :underline="underline"
    :type="type"
    :href="disabled ? '' : href"
    :target="target"
    :disabled="disabled"
    >{{ text }}</el-link
  >
</template>

<script>
export default {
  name: "ALink",
  data() {
    return {};
  },
  props: {
    value: [Array, Object],
    underline: Boolean,
    type: String,
    href: String,
    target: String,
    disabled: Boolean,
    text: String,
  },

  components: {},

  computed: {},

  mounted() {
    // console.log("mounted", this.underline, this.disabled);
  },

  methods: {},
};
</script>
<style lang="scss" scoped></style>
