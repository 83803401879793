import formCreate from "@form-create/element-ui";
import UploadImg from "./components/UploadImg";
import UploadFile from "./components/UploadFile";
import ALink from "./components/ALink";
import PassInput from "./components/PassInput";
import timeRange from "./components/timeRange";
formCreate.component("UploadImg", UploadImg);
formCreate.component("UploadFile", UploadFile);
formCreate.component("ALink", ALink);
formCreate.component("PassInput", PassInput);
formCreate.component("timeRange", timeRange);
export default formCreate;
