<!--  -->
<template>
  <div class="new-form-page">
    <!-- <TopWrapper
      :topObj="{
        bgColor: '#fff',
        title: '发起审批',
        txtColor: '#333',
        isBack: true,
        isMsg: false,
      }"
    /> -->
    <van-tabs v-model="active">
      <van-tab title="发起审批" name="1"> </van-tab>
      <van-tab title="查看数据" name="2"> </van-tab>
    </van-tabs>
    <!--左侧申请单填写 -->

    <!-- <template > -->
    <div class="form-left" v-show="1 == active">
      <div class="form-top">
        <form-auto
          v-if="detailInfo && tagKey !== 'CAR_APPLY_APPROVE'"
          :detailInfo="detailInfo"
          ref="stepForm"
          :formContent="formContent"
          :formValue="formValue"
        ></form-auto>
        <carFrom
          v-if="detailInfo && tagKey === 'CAR_APPLY_APPROVE'"
          :detailInfo="detailInfo"
          ref="stepForm"
          :formContent="formContent"
          :formValue="formValue"
        ></carFrom>
        <div class="line"></div>
        <process :formResults="formResults" ref="process"></process>
        <div class="is-urgent">
          <div class="label">是否加急</div>
          <van-switch
            v-model="urgent"
            :active-value="2"
            :inactive-value="1"
            size=".4rem"
          />
        </div>
      </div>

      <div class="btn">
        <!-- v-if="tagKey != 'CAR_APPLY_APPROVE'" -->
        <van-button @click="onBack" class="on-back">取消</van-button>
        <van-button @click="onDraft" class="on-draft">保存为草稿</van-button>
        <van-button @click="onsubmit" class="on-submit">提交</van-button>
      </div>
    </div>
    <!-- </template> -->
    <div class="list-right">
      <!-- 右侧申请单表格 -->
      <task-table class="right" v-show="2 == active"></task-table>
    </div>
  </div>
</template>

<script>
import TopWrapper from "@/components/topWrapper/index.vue";

import formAuto from "./components/form.vue";
import process from "./components/process.vue";
import taskTable from "./components/taskTable.vue";
import carFrom from "./components/carForm/index.vue";
import {
  apply,
  queryProcessConfigInfo,
  saveTemp,
  getApplyTemp,
  delApplyTemp,
} from "@/api/processApplyInfo";
import { queryFormInfo } from "@/api/processForm";
import { Toast, Dialog } from "vant";
export default {
  data() {
    return {
      urgent: 1,
      Api: null,
      detailInfo: null,
      formResults: null,
      formContent: "",
      active: 1,
      dialogVis: false,
      formValue: null,
    };
  },

  components: { formAuto, process, taskTable, carFrom, TopWrapper },

  computed: {
    tagKey() {
      if (!this.$route.query) return "";
      return this.$route.query.tagKey;
    },
  },

  created() {
    this.initData();
  },

  methods: {
    initData() {
      if (this.$route.query.bizCode) {
        this.getDraft(); //获取草稿
        this.getProcessConfigInfo();
      }
      this.getFormInfo();
    },
    /* 获取详情 */
    getProcessConfigInfo() {
      queryProcessConfigInfo({ processCode: this.$route.query.bizCode }).then(
        (res) => {
          let { data, success } = res;
          if (success) {
            this.detailInfo = data;
          }
        }
      );
    },

    /* 发起申请 */
    addApply(form, code) {
      const params = {
        processCode: this.$route.query.bizCode,
        bizTableName: this.detailInfo.bizTableName,
        noticeUser: this.detailInfo.noticeUser,
        urgent: this.urgent,
        workFlowKey: "process_simple_apply",
        formInfo: form,
      };
      if (code && code != "2000") {
        params.checkUserCode = code;
      }
      apply(params).then((res) => {
        let { success, msg } = res;
        Toast(msg);
        this.$router.push("/OAapproval");
      });
    },
    /* 提交申请表 */
    async onsubmit() {
      await Dialog.confirm({
        title: "确认提交",
        message: "确定要提交此申请么",
      });
      //校验当前步
      const validateData = await this.$refs.stepForm.validate();
      const processData = await this.$refs.process.validForm();

      if (validateData && processData) {
        this.addApply(validateData.formValue, processData);
      }
    },
    async onDraft() {
      //校验当前步
      const validateData = await this.$refs.stepForm.validate();
      const processData = await this.$refs.process.validForm();
      if (validateData && processData) {
        this.saveDraft(validateData.formValue, processData);
      }
    },
    saveDraft(form, code) {
      const params = {
        processCode: this.$route.query.bizCode,
        bizTableName: this.detailInfo.bizTableName,
        noticeUser: this.detailInfo.noticeUser,
        urgent: this.urgent,
        workFlowKey: "process_simple_apply",
        formInfo: form,
      };
      if (code && code != "2000") {
        params.checkUserCode = code;
      }
      saveTemp(params).then((res) => {
        let { success, msg } = res;
        Toast(msg);
        this.$router.push("/OAapproval");
      });
    },
    getFormInfo() {
      let processApplyCode = this.$route.query.applyCode;
      if (processApplyCode) {
        queryFormInfo({
          processApplyCode,
        }).then((res) => {
          this.formContent = res.data.formValue.formContent;
        });
      }
    },
    onBack() {
      this.$router.back();
    },
    getDraft() {
      getApplyTemp({ processCode: this.$route.query.bizCode }).then((res) => {
        if (res) {
          let { data, code } = res;
          if (data) {
            Dialog.confirm({
              message: "您已提交过草稿，是否载入草稿",
              confirmButtonText: "载入",
              confirmButtonColor: "#3c37d2",
              cancelButtonText: "取消",
              cancelButtonColor: "red",
              showCancelButton: true,
            })
              .then(() => {
                // console.log(data);
                this.formContent = data.formInfo;
                this.urgent = data.urgent;
              })
              .catch(() => {
                this.cancelDraf();
              });
          }
        }
      });
    },
    cancelDraf() {
      delApplyTemp({ processCode: this.$route.query.bizCode });
    },
  },
};
</script>
<style lang="scss" scoped>
.new-form-page {
  width: 7.5rem;
  background: #fafafa;
  overflow: hidden;
  height: 100%;

  .line {
    width: 100%;
    height: 1px;
    background: #fafafa;
  }
  .is-urgent {
    padding: 0 0.3rem;
    background: #fff;
    margin-top: 0.2rem;
    .label {
      line-height: 28px;
      padding: 0 0 10px 0;
      font-size: 14px;
      color: #606266;
    }
  }

  .form-left {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 2rem);
    justify-content: space-between;
    overflow: hidden;
    .form-top {
      flex: 1;
      height: calc(100vh - 2rem);
      overflow-y: auto;
    }
    .btn {
      display: flex;
      justify-content: center;
      margin-top: 0.2rem;
      background: #fff;
      align-items: center;
      height: 1.04rem;
      .on-back {
        width: 2.2rem;
        height: 0.8rem;
        background: rgba(0, 148, 238, 0.1);
        border-radius: 0.4rem;
        font-size: 0.26rem;
        color: #0094ee;
        line-height: 0.37rem;
      }
      .on-submit {
        width: 2.2rem;
        height: 0.8rem;
        background: #0094ee;
        border-radius: 0.4rem;
        font-size: 0.26rem;
        color: #ffffff;
        line-height: 0.37rem;
        margin-left: 0.4rem;
      }
      .on-draft {
        width: 2.2rem;
        height: 0.8rem;
        line-height: 0.37rem;
        margin-left: 0.4rem;
        color: #fff;
        background-color: #07c160;
        border: 1px solid #07c160;
        border-radius: 0.4rem;
      }
    }
  }
  .list-right {
    height: calc(100vh - 2rem);
    overflow-y: auto;
    .right {
      min-height: calc(100vh - 2rem);
      height: auto;
    }
  }
}
</style>
